<template>
  <div>
    <div class="mb-3">
      <CTypo tstyle="title3">
        <slot />
      </CTypo>
    </div>
    <div>
      <CSelect
        v-if="thisUser"
        :label="$t('chooseAddress')"
        @input="handleSelectedAddress"
        :value="selectedAddress"
        v-autofocus
      >
        <option :key="0" :value="null">{{ $t('createNewAddress') }}</option>
        <option
          v-for="address in addresses"
          :key="address.id"
          :value="address.id"
        >
          {{ getFormattedAddressString(address) }}
        </option>
      </CSelect>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-4">
      <div>
        <CTextField
          :disabled="!!selectedAddress"
          v-model="addressData.firstName"
          :label="$t('firstName')"
          autocomplete="given-name"
        />
      </div>
      <div>
        <CTextField
          :disabled="!!selectedAddress"
          v-model="addressData.lastName"
          :label="$t('lastName')"
          autocomplete="family-name"
        />
      </div>
    </div>
    <div>
      <CTextField
        :disabled="!!selectedAddress"
        v-model="addressData.companyName"
        :label="$t('company')"
        autocomplete="organization"
      />
    </div>
    <div class="grid grid-cols-12 gap-x-4">
      <div class="col-span-8 md:col-span-9">
        <CTextField
          :disabled="!!selectedAddress"
          v-model="addressData.street"
          :label="$t('address.street')"
          required
        />
      </div>
      <div class="col-span-4 md:col-span-3">
        <CTextField
          :disabled="!!selectedAddress"
          v-model="addressData.number"
          :label="$t('address.number')"
          required
        />
      </div>
      <div class="col-span-12">
        <CTextField
          v-model="postalCodeInput"
          ref="postalCodeInput"
          :disabled="!!selectedAddress"
          :label="$t('address.postalCode')"
          :data="currentPostalCodeResults"
          :debounce-typing="150"
          :clear-on-select="false"
          field="postalCode"
          required
          autocomplete-component
          :custom-error="postalCodeNotSupportedError"
          @typing="searchPostalCode"
          @select="postalCodeSelect"
        >
          <template v-slot="props">
            {{ props.option.postalCode }} {{ props.option.city }}
          </template>
        </CTextField>
      </div>
      <div class="col-span-12">
        <CTextField
          :label="$t('address.city')"
          :value="city"
          disabled
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { SEARCH_POSTAL_CODES } from '@/store/actionTypes';
import {
  ANONYMOUS_ORDER,
  CURRENT_POSTAL_CODE_RESULTS,
  DELIVERY_AREAS,
  THIS_USER,
} from '@/store/gettersTypes';
import { RESET_POSTAL_CODE_SEARCH } from '@/store/mutationTypes';

export default {
  props: {
    addresses: {
      type: Array,
      required: true,
    },
    storeMutation: {
      type: Function,
      required: true,
    },
    selectMutation: {
      type: Function,
      required: true,
    },
    storefrontSelectMutation: {
      type: Function,
      required: true,
    },
    selectedAddressId: {
      type: Number,
      required: false,
    },
    initAddress: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      selectedAddress: null,
      postalCodeInput: '',
      addressData: {
        firstName: '',
        lastName: '',
        companyName: '',
        street: '',
        number: null,
        postalCodeId: null,
      },
      privateProperty: true,
      helper: {
        selectedPostalCode: null,
      },
      postalCodeNotSupportedError: null,
    };
  },

  computed: {
    ...mapGetters([CURRENT_POSTAL_CODE_RESULTS, DELIVERY_AREAS, THIS_USER, ANONYMOUS_ORDER]),
    city() {
      return this.helper.selectedPostalCode?.city;
    },
  },

  watch: {
    addressData: {
      deep: true,
      handler() {
        if (this.addressData.postalCodeId) {
          const supported = this.deliveryAreas.some(
            (deliveryArea) => deliveryArea.postalCodeId === this.addressData.postalCodeId,
          );
          if (!supported) {
            this.postalCodeNotSupportedError = this.$t('postalCodeNotSupported');
            return;
          }
        }
        this.postalCodeNotSupportedError = null;
        this.storeMutation(this.addressData);
      },
    },
    selectedAddress() {
      if (this.selectedAddress) {
        this.selectMutation(this.selectedAddress);
        this.storefrontSelectMutation(this.selectedAddress);
        const address = this.addresses.find((address) => address.id === this.selectedAddress);

        this.addressData = {
          firstName: address.firstName,
          lastName: address.lastName,
          companyName: address.companyName,
          street: address.street,
          number: address.number,
        };

        this.postalCodeInput = address.location.postalCode;
        this.helper.selectedPostalCode = address.location;
        this.addressData.postalCodeId = address.location?.id || '';
      } else {
        this.selectMutation(null);
        this.storefrontSelectMutation(null);

        this.addressData = {
          firstName: this.thisUser?.firstName || '',
          lastName: this.thisUser?.lastName || '',
          companyName: '',
          street: '',
          number: null,
          postalCodeId: null,
        };

        this.postalCodeInput = '';
        this.helper.selectedPostalCode = null;
        this.addressData.postalCodeId = null;
      }
    },
  },

  created() {
    if (this.selectedAddressId) {
      this.selectedAddress = this.selectedAddressId;
    } else if (this.initAddress && Object.keys(this.initAddress).length > 1) {
      this.addressData = this.initAddress;
      if (this.addressData.postalCodeId) {
        [, this.postalCodeInput] = this.addressData.postalCodeId.split('DE-');
        this.searchPostalCode(this.postalCodeInput);
      }
    } else {
      this.addressData.firstName =
        this.thisUser?.firstName || this.anonymousOrder?.user?.firstName || '';
      this.addressData.lastName =
        this.thisUser?.lastName || this.anonymousOrder?.user?.lastName || '';
    }
  },

  methods: {
    ...mapActions([SEARCH_POSTAL_CODES]),
    ...mapMutations([RESET_POSTAL_CODE_SEARCH]),
    searchPostalCode(value) {
      const currentCountry = 'DE';
      if (value.length >= 3) {
        this.searchPostalCodes(`${currentCountry}-${value}`).then(() => {
          if (value.length === 5 && this.currentPostalCodeResults.length > 0) {
            this.postalCodeSelect(this.currentPostalCodeResults[0]);
          } else if (value.length === 5) {
            this.postalCodeSelect(null);
          }
        });
      } else {
        this.resetPostalCodeSearch();
      }
    },
    postalCodeSelect(data) {
      this.helper.selectedPostalCode = data;
      this.addressData.postalCodeId = data?.id || '';
    },
    handleSelectedAddress(addressId) {
      this.selectedAddress = addressId;
    },
    getFormattedAddressString(address) {
      const addressString = `${address.street} ${address.number}, ${address.location.postalCode}`;
      const companyName = address.companyName ? `, ${address.companyName}` : '';
      const name = address.firstName ? `, ${address.firstName} ${address.lastName}` : '';

      return addressString + companyName + name;
    },
  },
};
</script>
