<template>
  <div class="grid grid-cols-12 gap-3" v-if="selectedContainer">
    <CCard class="col-span-12 lg:col-span-7 px-4 py-2">
      <form ref="addressForm">
        <Address />
      </form>
    </CCard>
    <OrderCalculation
      class="col-span-12 lg:col-span-5"
      @onSubmit="onSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  SET_ADDRESS_CREATION_BILLING_ADDRESS,
  SET_ADDRESS_CREATION_BILLING_DIFFERS_FROM_DELIVERY,
  SET_ADDRESS_CREATION_DELIVERY_ADDRESS,
  SET_ANONYMOUS_ORDER,
  SET_DELIVERY_DATE,
  SET_NEXT_ROUTE,
  SET_PICKUP_DATE,
  SET_SELECTED_CONTAINER,
  SET_SELECTED_DELIVERY_AREA,
  SET_SELECTED_MERCHANT_WASTE,
} from '@/store/mutationTypes';
import {
  ADDRESS_CREATION,
  ANONYMOUS_ORDER,
  DELIVERY_DATE,
  MATCHTING_CONTAINERS,
  PICKUP_DATE,
  SELECTED_CONTAINER,
  SELECTED_DELIVERY_AREA,
  SELECTED_MERCHANT_WASTE,
  STOREFRONT_CONTAINER_SELECTION_LOADING,
  THIS_USER,
} from '@/store/gettersTypes';
import { CREATE_ADDRESS, GET_CONTAINER_SELECTION } from '@/store/actionTypes';
import { OrderCalculation } from '@/ui/components';
import Address from '../components/Address/index.vue';

export default {
  components: {
    Address,
    OrderCalculation,
  },

  computed: {
    ...mapGetters([
      SELECTED_MERCHANT_WASTE,
      SELECTED_DELIVERY_AREA,
      SELECTED_CONTAINER,
      DELIVERY_DATE,
      PICKUP_DATE,
      MATCHTING_CONTAINERS,
      STOREFRONT_CONTAINER_SELECTION_LOADING,
      THIS_USER,
      ADDRESS_CREATION,
      ANONYMOUS_ORDER,
      ADDRESS_CREATION,
    ]),
  },

  async created() {
    if (
      this.$route.query &&
      this.$route.query.merchantWasteId &&
      parseInt(this.$route.query.merchantWasteId, 10) &&
      this.$route.query.deliveryAreaId &&
      parseInt(this.$route.query.deliveryAreaId, 10) &&
      this.$route.query.productId &&
      parseInt(this.$route.query.productId, 10) &&
      this.$route.query.deliveryDate &&
      this.$DateTime.fromJSDate(new Date(this.$route.query.deliveryDate)).isValid &&
      this.$route.query.pickupDate &&
      this.$DateTime.fromJSDate(new Date(this.$route.query.pickupDate)).isValid
    ) {
      this.setSelectedMerchantWaste(parseInt(this.$route.query.merchantWasteId, 10));
      this.setSelectedDeliveryArea(parseInt(this.$route.query.deliveryAreaId, 10));
      this.setSelectedContainer(parseInt(this.$route.query.productId, 10));
      this.setDeliveryDate(new Date(this.$route.query.deliveryDate));
      this.setPickupDate(new Date(this.$route.query.pickupDate));
    }

    if (this.$route.query.anonymousOrder) {
      const anonymousOrder = JSON.parse(this.$route.query.anonymousOrder);
      this.setAnonymousOrder({ ...this.anonymousOrder, ...anonymousOrder });

      if (this.anonymousOrder.deliveryAddress) {
        this.setAddressCreationDeliveryAddress(this.anonymousOrder.deliveryAddress);
      }

      if (
        this.anonymousOrder.billingsAddressDiffersFromDeliveryAddress &&
        this.anonymousOrder.billingAddress
      ) {
        this.setAddressCreationBillingDiffersFromDelivery(true);
        this.setAddressCreationBillingAddress(this.anonymousOrder.billingAddress);
      }
    }

    if (!this.matchingContainers.length) {
      await this.getContainerSelection();
    }

    if (
      !this.selectedMerchantWaste ||
      !this.selectedDeliveryArea ||
      !this.selectedContainer ||
      !this.deliveryDate ||
      !this.pickupDate ||
      (!this.thisUser && !this.anonymousOrder.user)
    ) {
      this.$router.push('/');
    }
  },

  methods: {
    ...mapMutations([
      SET_SELECTED_MERCHANT_WASTE,
      SET_SELECTED_DELIVERY_AREA,
      SET_SELECTED_CONTAINER,
      SET_DELIVERY_DATE,
      SET_PICKUP_DATE,
      SET_NEXT_ROUTE,
      SET_ANONYMOUS_ORDER,
      SET_ADDRESS_CREATION_DELIVERY_ADDRESS,
      SET_ADDRESS_CREATION_BILLING_ADDRESS,
      SET_ADDRESS_CREATION_BILLING_DIFFERS_FROM_DELIVERY,
    ]),
    ...mapActions([GET_CONTAINER_SELECTION, CREATE_ADDRESS]),
    async onSubmit() {
      // hacky solution for validating disabled inputs
      const inputs = this.$refs.addressForm.querySelectorAll('input');
      let valid = true;
      inputs.forEach((input) => {
        if (input.disabled) {
          input.disabled = false;
          if (!input.checkValidity()) valid = false;
          input.disabled = true;
        } else if (!input.checkValidity()) valid = false;
      });

      if (valid) {
        if (this.thisUser) {
          let deliveryAddressId = this.addressCreation.selectedDeliveryAddressId;
          let billingAddressId = this.addressCreation.selectedBillingAddressId;

          await Promise.all([
            (async () => {
              if (!deliveryAddressId) {
                const data = await this.createAddress(this.addressCreation.deliveryAddress);
                deliveryAddressId = data.id;
              }
            })(),
            (async () => {
              if (
                this.addressCreation.billingsAddressDiffersFromDeliveryAddress &&
                !billingAddressId
              ) {
                const data = await this.createAddress(this.addressCreation.billingAddress);
                billingAddressId = data.id;
              }
            })(),
          ]);

          this.$router.push({
            path: '/order',
            query: {
              merchantWasteId: this.selectedMerchantWaste.id,
              deliveryAreaId: this.selectedDeliveryArea.id,
              productId: this.selectedContainer.id,
              deliveryDate: this.deliveryDate.toString(),
              pickupDate: this.pickupDate.toString(),
              deliveryAddressId,
              billingAddressId,
            },
          });
        } else {
          this.$router.push({
            path: '/order',
            query: {
              merchantWasteId: this.selectedMerchantWaste.id,
              deliveryAreaId: this.selectedDeliveryArea.id,
              productId: this.selectedContainer.id,
              deliveryDate: this.deliveryDate.toString(),
              pickupDate: this.pickupDate.toString(),
              anonymousOrder: JSON.stringify({
                ...this.anonymousOrder,
                deliveryAddress: this.addressCreation.deliveryAddress,
                billingsAddressDiffersFromDeliveryAddress:
                  this.addressCreation.billingsAddressDiffersFromDeliveryAddress,
                billingAddress: this.addressCreation.billingAddress,
              }),
            },
          });
        }
      }
    },
  },
};
</script>
