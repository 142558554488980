<template>
  <CContainer v-if="!addressesLoading">
    <AddressInputs
      :addresses="deliveryAddresses"
      :store-mutation="setAddressCreationDeliveryAddress"
      :select-mutation="setAddressCreationSelectedDeliveryAddressId"
      :storefront-select-mutation="setDeliveryAddress"
      :selected-address-id="selectedDeliveryAddress"
      :init-address="addressCreation.deliveryAddress"
    >
      {{ $t('enterDeliveryAddress') }}
    </AddressInputs>
    <div class="col-span-12">
      <CSwitch
        v-model="$store.state.orders.publicPropertyPlacement"
      >
        {{ $t('parkingSpaceIsOnPublicProperty') }}
      </CSwitch>
      <CMessage v-if="$store.state.orders.publicPropertyPlacement" variant="warning" class="mt-3">
        {{ $t('parkingPermissionRequired') }}
        <template v-slot:action>
          <CLink
            v-if="shop.publicPlacementNotes && shop.publicPlacementNotes.length > 0"
            @click="openPublicPlacementDialog"
          >
            {{ $t('learnMore') }}
          </CLink>
        </template>
      </CMessage>
    </div>
    <CCheckbox class="mt-3" v-model="billingAddressDiffersDeliveryAddress">
      {{ $t('billingAddressDiffersDeliveryAddress') }}
    </CCheckbox>
    <AddressInputs
      class="mt-6"
      v-if="billingAddressDiffersDeliveryAddress"
      :addresses="billingAddress"
      :store-mutation="setAddressCreationBillingAddress"
      :select-mutation="setAddressCreationSelectedBillingAddressId"
      :storefront-select-mutation="setBillingAddress"
      :selected-address-id="selectedBillingAddress"
      :init-address="addressCreation.billingAddress"
    >
      {{ $t('enterBilldingAddress') }}
    </AddressInputs>
    <Portal to="overlays">
      <PublicPlacementModal
        v-if="shop.publicPlacementNotes && shop.publicPlacementNotes.length > 0"
        v-model="placementDialog"
        :content="shop.publicPlacementNotes"
        :submit-label="$t('ok')"
      />
    </Portal>
  </CContainer>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { GET_ADDRESSES, GET_CONTAINER_SELECTION } from '@/store/actionTypes';
import {
  ALL_ADDRESSES,
  ADDRESS_CREATION,
  SELECTED_DELIVERY_ADDRESS,
  SELECTED_BILLING_ADDRESS,
  ADDRESSES_LOADING,
  SHOP,
  THIS_USER,
} from '@/store/gettersTypes';
import {
  SET_ADDRESS_CREATION_DELIVERY_ADDRESS,
  SET_ADDRESS_CREATION_BILLING_ADDRESS,
  SET_ADDRESS_CREATION_BILLING_DIFFERS_FROM_DELIVERY,
  SET_ADDRESS_CREATION_SELECTED_DELIVERY_ADDRESS_ID,
  SET_ADDRESS_CREATION_SELECTED_BILLING_ADDRESS_ID,
  SET_DELIVERY_ADDRESS,
  SET_BILLDING_ADDRESS,
} from '@/store/mutationTypes';
import AddressInputs from './AddressInputs.vue';
import PublicPlacementModal from '../PublicPlacementModal.vue';

export default {
  components: {
    AddressInputs,
    PublicPlacementModal,
  },

  data: () => ({
    placementDialog: false,
  }),

  computed: {
    ...mapGetters([
      ALL_ADDRESSES,
      ADDRESS_CREATION,
      ADDRESSES_LOADING,
      SELECTED_DELIVERY_ADDRESS,
      SELECTED_BILLING_ADDRESS,
      SHOP,
      THIS_USER,
      ADDRESS_CREATION,
    ]),
    billingAddressDiffersDeliveryAddress: {
      get() {
        return this.addressCreation.billingsAddressDiffersFromDeliveryAddress;
      },
      set(value) {
        this.setAddressCreationBillingDiffersFromDelivery(value);
      },
    },
    deliveryAddresses() {
      return this.allAddresses.filter((address) => address.type === 'delivery');
    },
    billingAddress() {
      return this.allAddresses.filter((address) => address.type === 'billing');
    },
  },

  watch: {
    selectedDeliveryAddress() {
      this.getContainerSelection();
    },
  },

  created() {
    if (this.thisUser) {
      this.getAddresses({
        limit: 300,
      });
    }
  },

  methods: {
    ...mapActions([GET_ADDRESSES, GET_CONTAINER_SELECTION]),
    ...mapMutations([
      SET_ADDRESS_CREATION_SELECTED_DELIVERY_ADDRESS_ID,
      SET_ADDRESS_CREATION_SELECTED_BILLING_ADDRESS_ID,
      SET_ADDRESS_CREATION_DELIVERY_ADDRESS,
      SET_ADDRESS_CREATION_BILLING_ADDRESS,
      SET_ADDRESS_CREATION_BILLING_DIFFERS_FROM_DELIVERY,
      SET_DELIVERY_ADDRESS,
      SET_BILLDING_ADDRESS,
    ]),
    openPublicPlacementDialog() {
      this.placementDialog = true;
    },
  },
};
</script>
